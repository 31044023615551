@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--mantine-font-family);
    scroll-behavior: smooth;
}

@layer utilities {
    .base-border {
        @apply border-solid border-1 border-gray-300 dark:border-dark-300;
    }

    .bg-dimmed {
        @apply bg-gray-100 dark:bg-dark;
    }
}