*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(51 154 240 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(51 154 240 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.pointer-events-none {
    pointer-events: none;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.-left-20 {
    left: -5rem;
}
.-right-20 {
    right: -5rem;
}
.-top-10 {
    top: -2.5rem;
}
.left-0 {
    left: 0px;
}
.left-2\/3 {
    left: 66.666667%;
}
.left-\[11\.25rem\] {
    left: 11.25rem;
}
.top-0 {
    top: 0px;
}
.top-20 {
    top: 5rem;
}
.top-24 {
    top: 6rem;
}
.top-\[13\.25rem\] {
    top: 13.25rem;
}
.z-10 {
    z-index: 10;
}
.-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.mt-36 {
    margin-top: 9rem;
}
.flex {
    display: flex;
}
.hidden {
    display: none;
}
.aspect-square {
    aspect-ratio: 1 / 1;
}
.h-4 {
    height: 1rem;
}
.h-full {
    height: 100%;
}
.h-screen {
    height: 100vh;
}
.max-h-screen {
    max-height: 100vh;
}
.min-h-0 {
    min-height: 0px;
}
.min-h-screen {
    min-height: 100vh;
}
.w-3 {
    width: 0.75rem;
}
.w-3\/4 {
    width: 75%;
}
.w-\[22rem\] {
    width: 22rem;
}
.w-\[2px\] {
    width: 2px;
}
.w-full {
    width: 100%;
}
.min-w-0 {
    min-width: 0px;
}
.min-w-\[22rem\] {
    min-width: 22rem;
}
.max-w-screen-lg {
    max-width: 1024px;
}
.max-w-screen-sm {
    max-width: 640px;
}
.flex-1 {
    flex: 1 1 0%;
}
.grow {
    flex-grow: 1;
}
.basis-0 {
    flex-basis: 0px;
}
.scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes ping {
    75%, 100% {
        transform: scale(2);
        opacity: 0;
    }
}
.animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
.cursor-pointer {
    cursor: pointer;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.flex-col {
    flex-direction: column;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-12 {
    gap: 3rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-6 {
    gap: 1.5rem;
}
.self-center {
    align-self: center;
}
.overflow-hidden {
    overflow: hidden;
}
.whitespace-pre-wrap {
    white-space: pre-wrap;
}
.break-all {
    word-break: break-all;
}
.rounded {
    border-radius: 0.5rem;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: 1rem;
}
.rounded-md {
    border-radius: 0.5rem;
}
.rounded-sm {
    border-radius: 0.25rem;
}
.rounded-xl {
    border-radius: 2rem;
}
.border-0 {
    border-width: 0px;
}
.border-b-8 {
    border-bottom-width: 8px;
}
.border-solid {
    border-style: solid;
}
.border-none {
    border-style: none;
}
.border-primary {
    --tw-border-opacity: 1;
    border-color: rgb(18 184 134 / var(--tw-border-opacity));
}
.bg-dark-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 38 43 / var(--tw-bg-opacity));
}
.bg-dark-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(26 27 30 / var(--tw-bg-opacity));
}
.bg-dark-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(16 17 19 / var(--tw-bg-opacity));
}
.bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(206 212 218 / var(--tw-bg-opacity));
}
.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}
.bg-orange {
    --tw-bg-opacity: 1;
    background-color: rgb(253 126 20 / var(--tw-bg-opacity));
}
.bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 192 120 / var(--tw-bg-opacity));
}
.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(18 184 134 / var(--tw-bg-opacity));
}
.bg-transparent {
    background-color: transparent;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.fill-yellow {
    fill: #fab005;
}
.stroke-dark-300 {
    stroke: #5c5f66;
}
.stroke-\[1\.5\] {
    stroke-width: 1.5;
}
.p-0 {
    padding: 0px;
}
.p-12 {
    padding: 3rem;
}
.p-\[3rem\] {
    padding: 3rem;
}
.p-\[4rem\] {
    padding: 4rem;
}
.p-md {
    padding: 1rem;
}
.p-xs {
    padding: 0.625rem;
}
.px-lg {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-md {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-xl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-xs {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}
.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.py-\[8rem\] {
    padding-top: 8rem;
    padding-bottom: 8rem;
}
.py-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-sm {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-xs {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.py-xxs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.pb-md {
    padding-bottom: 1rem;
}
.pl-md {
    padding-left: 1rem;
}
.pr-xs {
    padding-right: 0.625rem;
}
.pt-xs {
    padding-top: 0.625rem;
}
.text-center {
    text-align: center;
}
.text-end {
    text-align: end;
}
.font-mono {
    font-family: JetBrains Mono, monospace;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-5xl {
    font-size: 3rem;
    line-height: 1;
}
.text-8xl {
    font-size: 6rem;
    line-height: 1;
}
.text-lg {
    font-size: 1.125rem;
}
.text-md {
    font-size: 1rem;
}
.text-sm {
    font-size: 0.875rem;
}
.text-xl {
    font-size: 1.25rem;
}
.text-xs {
    font-size: 0.75rem;
}
.font-bold {
    font-weight: 700;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.uppercase {
    text-transform: uppercase;
}
.ordinal {
    --tw-ordinal: ordinal;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.tracking-widest {
    letter-spacing: 0.1em;
}
.text-blue {
    --tw-text-opacity: 1;
    color: rgb(34 139 230 / var(--tw-text-opacity));
}
.text-dark {
    --tw-text-opacity: 1;
    color: rgb(37 38 43 / var(--tw-text-opacity));
}
.text-dark-100 {
    --tw-text-opacity: 1;
    color: rgb(166 167 171 / var(--tw-text-opacity));
}
.text-gray {
    --tw-text-opacity: 1;
    color: rgb(134 142 150 / var(--tw-text-opacity));
}
.text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(222 226 230 / var(--tw-text-opacity));
}
.text-orange {
    --tw-text-opacity: 1;
    color: rgb(253 126 20 / var(--tw-text-opacity));
}
.text-orange-700 {
    --tw-text-opacity: 1;
    color: rgb(247 103 7 / var(--tw-text-opacity));
}
.text-primary {
    --tw-text-opacity: 1;
    color: rgb(18 184 134 / var(--tw-text-opacity));
}
.text-primary-500 {
    --tw-text-opacity: 1;
    color: rgb(32 201 151 / var(--tw-text-opacity));
}
.text-primary-700 {
    --tw-text-opacity: 1;
    color: rgb(12 166 120 / var(--tw-text-opacity));
}
.text-red {
    --tw-text-opacity: 1;
    color: rgb(250 82 82 / var(--tw-text-opacity));
}
.text-red-800 {
    --tw-text-opacity: 1;
    color: rgb(224 49 49 / var(--tw-text-opacity));
}
.text-violet {
    --tw-text-opacity: 1;
    color: rgb(121 80 242 / var(--tw-text-opacity));
}
.text-violet-500 {
    --tw-text-opacity: 1;
    color: rgb(132 94 247 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-yellow {
    --tw-text-opacity: 1;
    color: rgb(250 176 5 / var(--tw-text-opacity));
}
.no-underline {
    text-decoration-line: none;
}
.opacity-60 {
    opacity: 0.6;
}
.shadow {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.\!outline {
    outline-style: solid !important;
}
.outline {
    outline-style: solid;
}
.\!outline-4 {
    outline-width: 4px !important;
}
.outline-2 {
    outline-width: 2px;
}
.outline-offset-2 {
    outline-offset: 2px;
}
.\!outline-yellow {
    outline-color: #fab005 !important;
}
.outline-yellow {
    outline-color: #fab005;
}
.invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.base-border {
    border-width: 0.0625rem;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(222 226 230 / var(--tw-border-opacity));
}
:is(.dark .base-border) {
    --tw-border-opacity: 1;
    border-color: rgb(92 95 102 / var(--tw-border-opacity));
}
.bg-dimmed {
    --tw-bg-opacity: 1;
    background-color: rgb(241 243 245 / var(--tw-bg-opacity));
}
:is(.dark .bg-dimmed) {
    --tw-bg-opacity: 1;
    background-color: rgb(37 38 43 / var(--tw-bg-opacity));
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--mantine-font-family);
    scroll-behavior: smooth;
}

.hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 243 245 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(233 236 239 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

.hover\:bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(18 184 134 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgb(18 184 134 / var(--tw-text-opacity));
}

.hover\:text-primary-400:hover {
    --tw-text-opacity: 1;
    color: rgb(56 217 169 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:no-underline:hover {
    text-decoration-line: none;
}

.group:hover .group-hover\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:fill-primary-50 {
    fill: #e6fcf5;
}

.group:hover .group-hover\:fill-red-100 {
    fill: #ffe3e3;
}

.group:hover .group-hover\:text-primary {
    --tw-text-opacity: 1;
    color: rgb(18 184 134 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
    opacity: 1;
}

.data-\[active\=true\]\:bg-white[data-active=true] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark) {
    --tw-bg-opacity: 1;
    background-color: rgb(37 38 43 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(44 46 51 / var(--tw-bg-opacity));
}

:is(.dark .dark\:text-gray-100) {
    --tw-text-opacity: 1;
    color: rgb(241 243 245 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-300) {
    --tw-text-opacity: 1;
    color: rgb(222 226 230 / var(--tw-text-opacity));
}

:is(.dark .dark\:contrast-\[1\.1\]) {
    --tw-contrast: contrast(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:is(.dark .dark\:saturate-150) {
    --tw-saturate: saturate(1.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:is(.dark .dark\:hover\:bg-dark-400:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 58 64 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-dark-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(44 46 51 / var(--tw-bg-opacity));
}

:is(.dark .dark\:data-\[active\=true\]\:bg-dark-700[data-active=true]) {
    --tw-bg-opacity: 1;
    background-color: rgb(26 27 30 / var(--tw-bg-opacity));
}

@media (min-width: 768px) {
    .md\:block {
        display: block;
    }
    .md\:flex {
        display: flex;
    }
    .md\:hidden {
        display: none;
    }
    .md\:h-\[65rem\] {
        height: 65rem;
    }
    .md\:scale-110 {
        --tw-scale-x: 1.1;
        --tw-scale-y: 1.1;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:gap-0 {
        gap: 0px;
    }
    .md\:rounded-xl {
        border-radius: 2rem;
    }
    .md\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .md\:px-xl {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .md\:text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
    }
}

@media (min-width: 1024px) {
    .lg\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    .lg\:text-8xl {
        font-size: 6rem;
        line-height: 1;
    }
}

.\[\&\>div\]\:max-w-full>div {
    max-width: 100%;
}
